import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { CommonJumbotron } from "../../components/jumbotron";
import { SlidingTypography } from "../../components/sliding";
import ContainerBox from "../../components/containerBox";
import { pageContextType } from "../../pageContextType";

const links = [
  undefined,
  "https://arxiv.org/abs/1904.03816v1",
  "https://arxiv.org/abs/1904.03814",
  "https://hyperconnect.github.io/MarioNETte/",
  "https://hyperconnect.github.io/Attentron/",
  "https://arxiv.org/abs/2012.00321?fbclid=IwAR0PjlCywQFDOVtLBZTRoOf2IQU0wbI2RzrSQ23F-GuKbrD4iLcxRH4MywY",
  "https://arxiv.org/abs/2108.12582",
  "https://arxiv.org/abs/2104.07553",
  "https://ieeexplore.ieee.org/abstract/document/9679881",
  "https://arxiv.org/abs/2110.14131",
  "https://aclanthology.org/2022.nlp4convai-1.18/",
  "https://openreview.net/forum?id=rb4gLbiNil9",
  "https://link.springer.com/chapter/10.1007/978-3-031-19806-9_41",
  "https://aclanthology.org/2022.findings-emnlp.66/",
  "https://dl.acm.org/doi/abs/10.1145/3539597.3570439",
  "https://openaccess.thecvf.com/content/ICCV2023/html/Kye_TiDAL_Learning_Training_Dynamics_for_Active_Learning_ICCV_2023_paper.html",
  "https://arxiv.org/abs/2410.18087",
];
const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      paddingTop: "160px",
      paddingBottom: "80px",
    },
    flexRight: {
      display: "flex",
      flexDirection: "row-reverse",
      paddingTop: "80px",
    },
    sectionChunk: {
      marginBottom: "80px",
    },
    title: {
      color: "#222222",
      fontSize: "40px",
      fontWeight: 700,
      marginBottom: "20px",
      lineHeight: "1.3",
      maxWidth: "670px",
      wordBreak: "keep-all",
    },
    description: {
      color: "#858585",
      fontSize: "16px",
      marginBottom: "20px",
      maxWidth: "670px",

      "&:last-child": {
        marginBottom: 0,
      },
    },
    sectionImage: {
      height: "360px",
      objectFit: "cover",
      marginBottom: "80px",
    },
    link: {
      color: "#858585",
      whiteSpace: "pre-wrap",
    },
  })
);

const AIMLPage = ({ pageContext }: pageContextType) => {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;
  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "tech/img-title-photo-tech-02@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      AIPlaceholder1: file(relativePath: { eq: "tech/img-tech-ai-01@3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      AIPlaceholder2: file(relativePath: { eq: "tech/img-tech-ai-02@3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout language={language}>
      <SEO title="AI" description={intl.formatMessage({ id: "ai_lead" })} />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={"Artificial Intelligence  "}
      />

      <main className={classes.content}>
        <ContainerBox>
          <div>
            <div className={classes.sectionChunk}>
              <SlidingTypography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: "aiml_title_1" })}
              </SlidingTypography>
              <SlidingTypography component="p" className={classes.description}>
                {intl.formatMessage({ id: "aiml_description_1" })}
              </SlidingTypography>
            </div>

            <div className={classes.sectionChunk}>
              <SlidingTypography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: "aiml_title_2" })}
              </SlidingTypography>
              <SlidingTypography component="p" className={classes.description}>
                {intl.formatMessage({ id: "aiml_description_2" })}
              </SlidingTypography>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox leftOnly>
          <Img
            className={classes.sectionImage}
            fluid={data.AIPlaceholder1.childImageSharp.fluid}
            alt={intl.formatMessage({ id: "aiml_title_2" })}
          />
        </ContainerBox>

        <ContainerBox className={classes.flexRight}>
          <div>
            <div className={classes.sectionChunk}>
              <SlidingTypography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: "aiml_title_3" })}
              </SlidingTypography>
              <SlidingTypography component="p" className={classes.description}>
                {intl.formatMessage({ id: "aiml_description_3" })}
              </SlidingTypography>
            </div>

            <div className={classes.sectionChunk}>
              <SlidingTypography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: "aiml_title_4" })}
              </SlidingTypography>
              <SlidingTypography component="p" className={classes.description}>
                {intl.formatMessage({ id: "aiml_description_4" })}
              </SlidingTypography>
            </div>

            <div className={classes.sectionChunk}>
              <SlidingTypography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: "aiml_accomplishment_title" })}
              </SlidingTypography>
              <SlidingTypography component="p" className={classes.description}>
                <ul>
                  {links
                    .map((link, i) => {
                      const text = intl.formatMessage({
                        id: `aiml_accomplishment_item_${i + 1}`,
                      });
                      const content = link ? (
                        <a
                          href={link}
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferer"
                        >
                          {text}
                        </a>
                      ) : (
                        text
                      );
                      return <li>{content}</li>;
                    })
                    .reverse()}
                </ul>
              </SlidingTypography>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox rightOnly>
          <Img
            className={classes.sectionImage}
            fluid={data.AIPlaceholder2.childImageSharp.fluid}
            alt={intl.formatMessage({ id: "aiml_accomplishment_title" })}
          />
        </ContainerBox>
      </main>
    </Layout>
  );
};

export default AIMLPage;
